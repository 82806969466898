import {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { encode } from 'js-base64';
import { useServicesContext } from './Services';
import { useGlobalStateContext } from './GlobalState';
import { childrenPropType, ebcLocalStorage } from '../utils';

const UserDataContext = createContext([]);

const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(ebcLocalStorage.get('userData'));
  const { setAuthorizationToken, useGetUser } = useServicesContext();
  const { updateState } = useGlobalStateContext();
  const [{ data }] = useGetUser();

  useEffect(() => {
    if (data) {
      const authorizationEncoded = encode(`${data?.user.userName}:${data?.token}`);
      setAuthorizationToken(authorizationEncoded);
      setUserData(data?.user);
      updateState({ googleMapsApiKey: data?.settings.googleMapsApiKey });
    }
  }, [data]);

  useEffect(() => {
    ebcLocalStorage.set('userData', userData);
  }, [userData]);

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

const useUserDataContext = () => useContext(UserDataContext);

UserDataProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  UserDataContext,
  UserDataProvider,
  useUserDataContext,
};
