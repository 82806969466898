import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CountUp from 'react-countup';
import { useServicesContext } from '../contexts/Services';
import apis from '../apis';

const useStyles = makeStyles(({ palette: { common, primary }, spacing }) => ({
  text: {
    color: common.white,
    fontWeight: 700,
  },

  co2Value: {
    color: common.white,
    display: 'inline-block',
    textAlign: 'center',
    minWidth: 240,
    fontWeight: 700,
    marginLeft: spacing(2),
    backgroundColor: primary.main,
    padding: spacing(1, 4),
  },
}));

const Co2Header = () => {
  const { t } = useTranslation();
  const { text, co2Value } = useStyles();
  const { useGetCo2 } = useServicesContext();
  const [{ data }] = useGetCo2(apis.co2);

  return (
    <Typography variant="h1">
      <span className={text} dangerouslySetInnerHTML={{ __html: t('co2-title') }} />
      <span className={co2Value}>
        <CountUp
          start={0.00}
          end={data?.sums['SUM:co2'] / 1000}
          duration={1}
          decimals={2}
          decimal=","
          suffix=" Kg"
        />
      </span>
    </Typography>
  );
};

export default Co2Header;
