import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { childrenPropType } from '../utils';
import { useServicesContext } from './Services';
import { useUserPreferencesContext } from './UserPreferences';
import useNavigation from '../hooks/useNavigation';

const AccountContext = createContext([]);

const AccountProvider = ({ children }) => {
  const {
    useGetAccounts,
    setAccountParams,
    setSelectedAccount: setSelectedAccountService,
  } = useServicesContext();
  const {
    userPreferences: { selectedAccount: selectedAccountPreference },
    updatePreferences,
  } = useUserPreferencesContext();
  const [{ loading, data }] = useGetAccounts();
  const [selectedAccount, setSelectedAccount] = useState(selectedAccountPreference);
  const navigateTo = useNavigation();
  const location = useLocation();

  useEffect(() => {
    setSelectedAccountService(data?.list.find(({ id }) => id === selectedAccount));
  }, [selectedAccount, data]);

  /**
   * Update selected account state when api called first time but nothing in preferences
   */
  useEffect(() => {
    const selectedId = data?.list[0]?.id;

    if (!selectedAccountPreference) {
      setSelectedAccount(selectedId);
    }
  }, [data]);

  const redirectOnContractPage = () => {
    const currentAccount = data?.list.find(({ id }) => id === selectedAccount);
    if ((currentAccount?.contractType === 'rechargeable' && location.pathname === '/invoices')
      || (currentAccount?.contractType === 'contract' && location.pathname === '/recharges')) navigateTo('dashboard', true);
  };

  /**
   * When select account update axios params need and user preferences
   * Redirect if user is on account specific page
   */
  useEffect(() => {
    updatePreferences({ selectedAccount });
    redirectOnContractPage();
    setAccountParams({
      accountId: selectedAccount,
      where: [{
        type: 'equals',
        attribute: 'accountId',
        value: selectedAccount,
      }],
    });
  }, [selectedAccount]);

  const accountState = {
    accounts: data?.list || [],
    loading,
    selectedAccount,
    setSelectedAccount,
  };

  return (
    <AccountContext.Provider value={accountState}>
      {children}
    </AccountContext.Provider>
  );
};

const useAccountContext = () => useContext(AccountContext);

AccountProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  AccountContext,
  AccountProvider,
  useAccountContext,
};
