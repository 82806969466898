/**
 * Translation file: shipping.json
 */
const ShippingStatuses = {
  payment_required: {
    label: 'status-payment-required',
    variant: 'filled',
    color: 'error',
  },
  new: {
    label: 'status-new',
    variant: 'filled',
    color: 'azure',
  },
  assigned: {
    label: 'status-assigned',
    variant: 'filled',
    color: 'orange',
  },
  pickedup: {
    label: 'status-picked-up',
    variant: 'filled',
    color: 'orange',
  },
  waiting: {
    label: 'status-waiting',
    variant: 'filled',
    color: 'orange',
  },
  delivering: {
    label: 'status-delivering',
    variant: 'outlined',
    color: 'orange',
  },
  delivered: {
    label: 'status-delivered',
    variant: 'outlined',
    color: 'primary',
  },
  exception: {
    label: 'status-exception',
    variant: 'filled',
    color: 'error',
  },
  archived: {
    label: 'status-archived',
    variant: 'filled',
    color: 'black',
  },
  processing: {
    label: 'status-processing',
    variant: 'filled',
    color: 'orange',
  },
};

/**
 * Translation file: cod.json
 */
const CodStatuses = {
  pending: {
    label: 'status-pending',
    color: 'orange',
    variant: 'filled',
  },
  collected: {
    label: 'status-collected',
    color: 'primary',
    variant: 'filled',
  },
  payed: {
    label: 'status-payed',
    color: 'black',
    variant: 'filled',
  },
};

/**
 * translation file: invoice.json
 */
const InvoiceStatuses = {
  Draft: {
    label: 'status-draft',
    variant: 'filled',
    color: 'black',
  },
  'In Review': {
    label: 'status-in-review',
    variant: 'filled',
    color: 'black',
  },
  Confirmed: {
    label: 'status-confirmed',
    variant: 'outlined',
    color: 'orange',
  },
  sent: {
    label: 'status-sent',
    variant: 'outlined',
    color: 'primary',
  },
  Paid: {
    label: 'status-paid',
    variant: 'outlined',
    color: 'primary',
  },
  Rejected: {
    label: 'status-rejected',
    variant: 'filled',
    color: 'error',
  },
  Canceled: {
    label: 'status-canceled',
    variant: 'filled',
    color: 'error',
  },
};

/**
 * translation file: recharge.json
 */
const RechargeStatuses = {
  completed: {
    label: 'status-completed',
    variant: 'outlined',
    color: 'primary',
  },
  pending: {
    label: 'status-pending',
    variant: 'outlined',
    color: 'orange',
  },
  canceled: {
    label: 'status-canceled',
    variant: 'filled',
    color: 'error',
  },
  errored: {
    label: 'status-errored',
    variant: 'filled',
    color: 'error',
  },
  refunded: {
    label: 'status-refunded',
    variant: 'filled',
    color: 'black',
  },
};

/**
 * Translation file: recharge.json
 */
const Sources = {
  cash: {
    label: 'source-cash',
    variant: 'filled',
    color: 'black',
  },
  paypal: {
    label: 'source-paypal',
    variant: 'filled',
    color: 'black',
  },
  branktransfer: {
    label: 'source-banktransfer',
    variant: 'filled',
    color: 'black',
  },
  stripe: {
    label: 'source-stripe',
    variant: 'filled',
    color: 'black',
  },
  adjustment: {
    label: 'source-adjustment',
    variant: 'filled',
    color: 'black',
  },
};

/**
 * Translation file: shipping.json
 */
const PaymentTypes = {
  cash: {
    label: 'payment-type-cash',
    variant: 'outlined',
    color: 'primary',
  },
  sumup: {
    label: 'payment-type-sumup',
    variant: 'filled',
    color: 'azure',
  },
  branktransfer: {
    label: 'payment-type-banktransfer',
    variant: 'filled',
    color: 'azure',
  },
  other: {
    label: 'payment-type-other',
    variant: 'outlined',
    color: 'orange',
  },
};

const CurrencySymbolMapping = {
  EUR: '€',
};

export {
  ShippingStatuses,
  CodStatuses,
  InvoiceStatuses,
  RechargeStatuses,
  Sources,
  PaymentTypes,
  CurrencySymbolMapping,
};
