const colors = {
  green: {
    alias: 'primary',
    variants: {
      light: { hex: '#5cef62', rgb: [92, 239, 98] },
      main: { hex: '#4db433', rgb: [0, 187, 49] },
      dark: { hex: '#008900', rgb: [0, 137, 0] },
      contrastText: { hex: '#ffffff', rbg: [255, 255, 255] },
    },
  },

  black: {
    alias: 'black',
    variants: {
      light: { hex: '#545C66', rgb: [99, 99, 99] },
      main: { hex: '#393939', rgb: [57, 57, 57] },
      dark: { hex: '#131313', rgb: [19, 19, 19] },
      contrastText: { hex: '#ffffff', rbg: [255, 255, 255] },
    },
  },

  white: {
    alias: 'white',
    variants: {
      main: { hex: '#ffffff', rgb: [255, 255, 255] },
      contrastText: { hex: '#000000', rbg: [0, 0, 0] },
    },
  },

  grey: {
    alias: 'grey',
    variants: {
      main: { hex: '#f2f2f2', rgb: [242, 242, 242] },
      dark: { hex: '#c4c4c4', rgb: [196, 196, 196] },
      contrastText: { hex: '#000000', rbg: [0, 0, 0] },
    },
  },

  azure: {
    alias: 'azure',
    variants: {
      light: { hex: '#F4F7FC', rgb: [244, 247, 252] },
      main: { hex: '#4dabf5', rgb: [253, 216, 53] },
      contrastText: { hex: '#fff', rbg: [255, 255, 255] },
    },
  },

  red: {
    alias: 'error',
    variants: {
      main: { hex: '#B22222', rgb: [178, 34, 34] },
    },
  },

  orange: {
    alias: 'orange',
    variants: {
      main: { hex: '#fdd835', rgb: [253, 216, 53] },
      contrastText: { hex: '#000000', rgb: [0, 0, 0] },
    },
  },
};

export default colors;
