import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FadeIn from 'react-fade-in';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MetaTags from 'react-meta-tags';
import MainHashtag from '../MainHashtag/MainHashtag';
import { useAuthenticationContext } from '../../contexts/Authentication';
import { childrenPropType } from '../../utils';

const Page = ({ title, component: Component, authenticated }) => {
  const { t } = useTranslation('pages');
  const { checkAuth } = useAuthenticationContext();

  useEffect(() => {
    if (authenticated) {
      checkAuth();
    }
  }, []);

  return (
    <>
      <MetaTags>
        <title>{`EBC - ${t(title)}`}</title>
      </MetaTags>

      <FadeIn>
        {authenticated && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h1">
              {t(title)}
            </Typography>
            <MainHashtag />
          </Box>
        )}

        <Box mt={4}>
          <Component />
        </Box>
      </FadeIn>
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  component: childrenPropType.isRequired,
  authenticated: PropTypes.bool,
};

Page.defaultProps = {
  title: '',
  authenticated: false,
};

export default Page;
