import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthenticationProvider } from './contexts/Authentication';
import { AlertProvider } from './contexts/Alert';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ServicesProvider } from './contexts/Services';
import { UserDataProvider } from './contexts/UserData';
import { UserPreferencesProvider } from './contexts/UserPreferences';
import { GlobalStateProvider } from './contexts/GlobalState';
import { EbcModalProvider } from './contexts/EbcModal';
import ebcMainTheme from './themes';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AlertProvider>
        <UserPreferencesProvider>
          <GlobalStateProvider>
            <ServicesProvider>
              <UserDataProvider>
                <AuthenticationProvider>
                  <CssBaseline />
                  <ThemeProvider theme={ebcMainTheme}>
                    <EbcModalProvider>
                      <App />
                    </EbcModalProvider>
                  </ThemeProvider>
                </AuthenticationProvider>
              </UserDataProvider>
            </ServicesProvider>
          </GlobalStateProvider>
        </UserPreferencesProvider>
      </AlertProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// eslint-disable-next-line no-console
reportWebVitals(console.log);
