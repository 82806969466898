/* eslint-disable react/forbid-prop-types */
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

const Icon = ({ Svg }) => (
  <SvgIcon
    component={Svg}
    sx={{ fill: 'none' }}
    viewBox="0 0 33 33"
  />
);

Icon.propTypes = {
  Svg: PropTypes.object.isRequired,
};

export default Icon;
