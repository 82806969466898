import { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Page from './components/Page';
import GlobalLoading from './components/GlobalLoading';
import { AccountProvider } from './contexts/Account';
import Layout from './layout';
import { navigationRoutes, otherRoutes } from './routes';

import './libraries/i18n';

const App = () => {
  /**
   * Routes renderer
   */
  const renderRoutes = (routes) => routes
    .filter(({ link }) => {
      if (link === true) {
        return false;
      }
      return true;
    })
    .map(({
      path,
      exact,
      authenticated,
      title,
      component,
    }) => (
      <Route
        key={`${path}-${title}`}
        path={path}
        exact={exact}
        render={() => (
          <Page
            title={title}
            component={component}
            authenticated={authenticated}
          />
        )}
      />
    ));

  /**
   * Return
   */
  return (
    <Suspense fallback={<GlobalLoading />}>
      <Switch>
        <Redirect exact from="/" to="dashboard" />
        {renderRoutes(otherRoutes)}
        <AccountProvider>
          <Layout>
            {renderRoutes(navigationRoutes)}
          </Layout>
        </AccountProvider>
      </Switch>
    </Suspense>
  );
};

export default App;
