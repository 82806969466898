const {
  REACT_APP_CO2_REPORT_ID,
  REACT_APP_ORDER_REPORT_ID,
  REACT_APP_API_URL,
  REACT_APP_IMPORT_ORDER_CSV,
} = process.env;

const apis = {
  login: {
    method: 'get',
    url: 'App/user',
  },

  recoverPassword: {
    method: 'post',
    url: 'User/passwordChangeRequest',
    headers: {},
  },

  changePasswordByRequest: {
    method: 'post',
    url: `${REACT_APP_API_URL}/User/changePasswordByRequest`,
    headers: {},
  },

  accounts: {
    method: 'get',
    url: 'Account',
  },

  co2: {
    method: 'get',
    url: `Report/action/run?id=${REACT_APP_CO2_REPORT_ID}`,
  },

  ordersReport: {
    method: 'get',
    url: `Report/action/run?id=${REACT_APP_ORDER_REPORT_ID}`,
  },

  lastOrders: {
    method: 'get',
    url: 'Order?maxSize=5&orderBy=createdAt&order=desc&where[1][type]=notIn&where[1][attribute]=status&where[1][value][0]=creating',
  },

  orders: {
    methods: 'get',
    url: 'Order',
  },

  recharges: {
    method: 'get',
    url: 'Recharge',
  },

  orderDetail: {
    methods: 'get',
    url: 'Order/{id}',
  },

  timeSlots: {
    method: 'get',
    url: 'TimeSlot?offset=0&maxSize=10&orderBy=id&order=asc&where[0][attribute]=zipCodes&where[0][type]=arrayAnyOf&where[0][value][]={dinamico_zip}&where[1][attribute]=days&where[1][type]=arrayAnyOf&where[1][value][]={giorno_della_settimana__oggi+2--domenica0-lunedì-1}',
  },

  faq: {
    method: 'get',
    url: 'KnowledgeBaseArticle?offset=0&maxSize=10&orderBy=id&order=asc',
  },

  fileAttachment: {
    method: 'get',
    url: 'Attachment/file/{id}',
  },

  cods: {
    method: 'get',
    url: 'Cod',
  },

  invoices: {
    method: 'get',
    url: 'Invoice',
  },

  uploadOrdersCSV: {
    method: 'post',
    url: REACT_APP_IMPORT_ORDER_CSV,
  },

  servicesList: {
    method: 'get',
    url: 'Priceoption?offset=0&maxSize=10&orderBy=id&order=asc&where%5B0%5D%5Battribute%5D=pricelistId&where%5B0%5D%5Btype%5D=equals&where%5B0%5D%5Bvalue%5D=',
  },

  createOrder: {
    method: 'post',
    url: 'Order',
  },

  updateOrder: {
    method: 'put',
    url: 'Order/{id}',
  },

  changeOwnPassword: {
    method: 'post',
    url: 'User/action/changeOwnPassword',
  },

  updateAccount: {
    method: 'put',
    url: 'Account/',
  },
};

export default apis;
