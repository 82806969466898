const GlobalLoading = () => (
  <div
    style={{
      widht: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    Loading...
  </div>
);

export default GlobalLoading;
