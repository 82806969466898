import { memo } from 'react';
import PropTypes from 'prop-types';
import random from 'lodash/random';
import MuiSkeleton from '@mui/material/Skeleton';

const Skeleton = ({ variant, height, width }) => {
  const getWidth = () => {
    const isArray = Array.isArray(width);
    if (isArray && width.length > 1) return random(width[0], width[1]);
    if (isArray && width.length === 1) return random(width[0]);
    return width || random(50, 100);
  };

  return (
    <MuiSkeleton
      variant={variant}
      height={height}
      width={getWidth()}
    />
  );
};

Skeleton.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  variant: PropTypes.oneOf(['text', 'rect', 'circular']),
};

Skeleton.defaultProps = {
  height: undefined,
  width: undefined,
  variant: 'text',
};

export default memo(Skeleton);
