import { lazy } from 'react';

/**
 * Icons
 */
import {
  DashboardIcon,
  ShippingCreateIcon,
  FaqIcon,
  InvoicesIcon,
  ListIcon,
  LogoutIcon,
  ProfileIcon,
  ContactUsIcon,
} from './components/Icons';

const { REACT_APP_CONTACT_EMAIL } = process.env;

/**
 * Pages lazy imported
 */
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword'));
const CompleteRecoverPassword = lazy(() => import('./pages/CompleteRecoverPassword'));
const Registration = lazy(() => import('./pages/Registration'));
const ShippingCreate = lazy(() => import('./pages/ShippingCreate'));
const Faq = lazy(() => import('./pages/Faq'));
const ShippingList = lazy(() => import('./pages/ShippingList'));
const ShippingPayList = lazy(() => import('./pages/ShippingPayList'));
const RechargeList = lazy(() => import('./pages/RechargeList'));
const InvoiceList = lazy(() => import('./pages/InvoiceList'));
const Profile = lazy(() => import('./pages/Profile'));

/**
 * Drawer navigation routes
 */
const navigationRoutes = [{
  path: '/dashboard',
  exact: true,
  authenticated: true,
  title: 'dashboard',
  component: Dashboard,
  icon: DashboardIcon,
}, {
  path: '/shipping-create',
  authenticated: true,
  title: 'shipping-create',
  component: ShippingCreate,
  icon: ShippingCreateIcon,
}, {
  path: '/shipping-list',
  authenticated: true,
  title: 'shipping-list',
  component: ShippingList,
  icon: ListIcon,
}, {
  path: '/shipping-pay-list',
  authenticated: true,
  title: 'shipping-pay-list',
  component: ShippingPayList,
  icon: ListIcon,
}, {
  path: '/invoices',
  authenticated: true,
  title: 'invoices',
  component: InvoiceList,
  accountFilterCallback: ({ contractType }) => contractType === 'contract',
  icon: InvoicesIcon,
},
{
  path: '/recharges',
  authenticated: true,
  title: 'recharges',
  component: RechargeList,
  accountFilterCallback: ({ contractType }) => contractType === 'rechargeable',
  icon: InvoicesIcon,
}, {
  path: '/faq',
  authenticated: true,
  title: 'faq',
  component: Faq,
  icon: FaqIcon,
}, {
  link: true,
  title: 'contact-us',
  onClick: () => window.open(`mailto:${REACT_APP_CONTACT_EMAIL}`),
  icon: ContactUsIcon,
}, {
  path: '/profile',
  authenticated: true,
  title: 'profile',
  component: Profile,
  icon: ProfileIcon,
}, {
  path: '/logout',
  authenticated: true,
  title: 'logout',
  component: Logout,
  icon: LogoutIcon,
}];

/**
 * Other routes (usually not authenticated)
 */
const otherRoutes = [{
  path: '/login',
  title: 'login',
  component: Login,
}, {
  path: '/recover-password',
  title: 'recover-password',
  component: RecoverPassword,
}, {
  path: '/complete-recover-password',
  title: 'complete-recover-password',
  component: CompleteRecoverPassword,
}, {
  path: '/registration',
  title: 'registration',
  component: Registration,
}, {
  path: '/logout',
  component: Logout,
}];

export { navigationRoutes, otherRoutes };
