import { createTheme } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';
import palette from './palette';
import components from './components';
import getTypography from './typography';

const ebcMainTheme = createTheme({
  components,
  palette,
}, itIT);

export default createTheme(ebcMainTheme, {
  typography: getTypography(ebcMainTheme),
});
