import { useReducer, createContext, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useModalState from '../hooks/useModalState';
import { childrenPropType } from '../utils';

const AlertContext = createContext([]);

const initialState = {
  duration: 3000,
  position: { vertical: 'top', horizontal: 'right' },
  severity: 'info',
  content: '',
};

const alertReducer = (_, payload) => ({ ...initialState, ...payload });

const AlertProvider = ({ children }) => {
  const { isOpened, close, open: openModal } = useModalState();
  const [state, dispatch] = useReducer(alertReducer, initialState);

  const open = ({
    duration,
    position,
    severity,
    content,
  } = {}) => {
    dispatch({
      duration,
      position,
      severity,
      content,
    });
    openModal();
  };

  return (
    <AlertContext.Provider value={{ isOpened, close, open }}>
      <Snackbar
        open={isOpened}
        autoHideDuration={state.duration}
        anchorOrigin={state.position}
        onClose={close}
      >
        <Alert severity={state.severity}>{state.content}</Alert>
      </Snackbar>

      {children}
    </AlertContext.Provider>
  );
};

const useAlertContext = () => useContext(AlertContext);

AlertProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  AlertContext,
  AlertProvider,
  useAlertContext,
};
