const getTypography = ({ palette: { primary, black } }) => ({
  fontFamily: [
    'Roboto',
    'Arial',
    'sans-serif',
  ].join(','),

  fontSize: 16,

  h1: {
    fontSize: '2rem',
    fontWeight: 900,
    color: black.light,
  },

  h2: {
    fontSize: '1.5rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: primary.main,
  },

  h6: {
    fontSize: '2.5rem',
    fontWeight: 900,
    color: primary.main,
    textTransform: 'uppercase',
    '& .dark': {
      color: black.light,
    },
  },
});

export default getTypography;
