/* eslint-disable no-console */
import axios from 'axios';
import Qs from 'qs';
import { ebcLocalStorage } from '../utils';

const { CancelToken } = axios;
const source = CancelToken.source();

const { REACT_APP_EBC_BASE_URL } = process.env;

/**
 * Axios instance
 */
const ebcAxiosInstance = axios.create({
  baseURL: REACT_APP_EBC_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  cancelToken: source.token,
  paramsSerializer: (params) => Qs.stringify(params),
  // paramsSerializer: (params) => Qs.stringify(params, { encodeValuesOnly: true }),
});

ebcAxiosInstance.interceptors.response.use((response) => response, (error) => {
  try {
    const { response: { status } = {} } = error;

    if ((status === 401 || status === 400) && window.location.pathname !== '/login') {
      ebcLocalStorage.clearStorage();
      window.location.replace('/login');
    }
  } catch (e) {
    console.error('error', e);
  }

  return Promise.reject(error);
});

export default ebcAxiosInstance;
