import { useState } from 'react';

const useAvatarMenu = (initialState = false) => {
  const [anchorEl, setAnchorEl] = useState(initialState);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return ({
    anchorEl,
    open,
    handleClick,
    handleClose,
  });
};

export default useAvatarMenu;
