import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Version from '../components/Version';

const DrawerFooter = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between">
      <Grid item sx={{ p: 2 }}>
        <Version />
      </Grid>
      <Grid item sx={{ p: 2 }}>
        <Typography>
          <Link href="https://ebcgenova.it/privacy-policy/" target="_blank" rel="noreferrer">
            {t('privacy-policy')}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

DrawerFooter.propTypes = {

};

export default DrawerFooter;
