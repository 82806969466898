import { useHistory } from 'react-router-dom';

const useNavigation = () => {
  const history = useHistory();
  const historyPush = (path) => { history.push(path); };

  return ((path, istant = false) => {
    const [actualPath] = Array.isArray(path) ? path : [path];

    if (istant) {
      historyPush(actualPath);
    } else {
      return () => historyPush(actualPath);
    }

    return null;
  });
};

export default useNavigation;
