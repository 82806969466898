import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from './ListItemText';
import useNavigation from '../../hooks/useNavigation';
import { childrenPropType } from '../../utils';

const NavigationListItem = ({
  disabled,
  icon: Icon,
  path,
  title,
  onClick,
}) => {
  const { t } = useTranslation('pages');
  const navigateTo = useNavigation();

  return (
    <ListItem
      disabled={disabled}
      button
      onClick={onClick || navigateTo(path)}
      sx={{ px: 2.5 }}
    >
      <ListItemIcon>
        <Icon color="white" />
      </ListItemIcon>
      <ListItemText
        path={path}
        title={t(title)}
      />
    </ListItem>
  );
};

NavigationListItem.propTypes = {
  disabled: PropTypes.bool,
  icon: childrenPropType,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  title: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationListItem.defaultProps = {
  disabled: false,
  icon: null,
  path: '',
  title: '',
  onClick: undefined,
};

export default NavigationListItem;
