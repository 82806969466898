import colors from './colors';

const getVariants = (accumulator, [key, { hex }]) => ({
  ...accumulator,
  [key]: hex,
});

const getColors = (accumulator, { alias, variants }) => ({
  ...accumulator,
  [alias]: Object.entries(variants).reduce(getVariants, {}),
});

const palette = Object.values(colors).reduce(getColors, {});

export default palette;
