import PropTypes from 'prop-types';

const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
  PropTypes.shape(),
]);

const ebcLocalStorage = {
  get: (key) => localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)),
  set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  clearItem: (key) => localStorage.setItem(key, null),
  removeItem: (key) => localStorage.removeItem(key),
  clearStorage: () => localStorage.clear(),
};

const getLocaleDateFnsFormat = (code) => code.split('-').reduce((language, country) => {
  if (language.toLowerCase() === country.toLowerCase()) {
    return language.toLowerCase();
  }

  return `${language.toLowerCase()}${country.toUpperCase()}`;
});

export {
  childrenPropType,
  ebcLocalStorage,
  getLocaleDateFnsFormat,
};
