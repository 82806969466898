import { useReducer, useEffect } from 'react';
import { ebcLocalStorage } from '../utils';

/**
 * Default configurations
 */
const defaultUserPreferences = {
  navigationDrawerIsOpened: true,
};

/**
 * Reducer
 */
const userPreferencesReducer = (state, { type, payload }) => {
  if (type === 'update') return ({ ...state, ...payload });
  if (type === 'reset') return defaultUserPreferences;
  return state;
};

/**
 * Hook
 */
const useUserPreferences = (initialState = defaultUserPreferences) => {
  const [userPreferences, dispatch] = useReducer(userPreferencesReducer, initialState);

  // Store state in local storage
  useEffect(() => {
    ebcLocalStorage.set('userPreferences', userPreferences);
  }, [userPreferences]);

  // Methods
  const updatePreferences = (payload) => { dispatch({ type: 'update', payload }); };
  const resetPreferences = () => { dispatch({ type: 'reset' }); };

  return {
    userPreferences,
    updatePreferences,
    resetPreferences,
  };
};

export default useUserPreferences;
