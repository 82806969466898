const components = {
  MuiToolbar: {
    styleOverrides: {
      regular: {
        minHeight: 120,
        '@media (min-width: 600px)': {
          minHeight: 150,
        },
      },
    },
  },
};

export default components;
