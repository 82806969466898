/* eslint-disable max-len */
import {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import axios from 'axios';
import Qs from 'qs';
import { makeUseAxios } from 'axios-hooks';
import { childrenPropType, ebcLocalStorage } from '../utils';
import ebcAxiosInstance from '../libraries/axios';
import apis from '../apis';

const ServicesContext = createContext([]);

const ServicesProvider = ({ children }) => {
  const useEbcAxios = makeUseAxios({ axios: ebcAxiosInstance });
  const useAxios = makeUseAxios({
    axios: axios.create({ paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }) }),
  });
  const [authorizationToken, setAuthorizationToken] = useState(ebcLocalStorage.get('authToken'));
  const [accountParams, setAccountParams] = useState();
  const [selectedAccount, setSelectedAccount] = useState();

  useEffect(() => {
    ebcLocalStorage.set('authToken', authorizationToken);
  }, [authorizationToken]);

  // Create a custom axios instance hook that refresh auth token
  const useEbcService = (axiosParams, hookOptions) => useEbcAxios(
    {
      headers: { 'Espo-Authorization': authorizationToken },
      params: hookOptions?.avoidAccountParams ? null : accountParams,
      ...axiosParams,
    },
    hookOptions,
  );

  /**
   * Services
   */
  const useGetLogin = () => useEbcService(apis.login, { manual: true });
  const useGetUser = () => useEbcService(apis.login);
  const useGetAccounts = () => useEbcService(apis.accounts, { avoidAccountParams: true });
  const usePutAccount = () => {
    const [response, putAccount] = useEbcService(apis.updateAccount, { manual: true, avoidAccountParams: true });

    return [response, ({ accountId, data }) => {
      putAccount({
        ...apis.updateAccount,
        url: `${apis.updateAccount.url}${accountId}`,
        data,
      });
    }];
  };
  const useGetCo2 = () => useEbcService(apis.co2);
  const useGetOrdersReport = () => useEbcService(apis.ordersReport);
  const useGetLastOrders = () => useEbcService(apis.lastOrders);
  const useGetOrders = () => useEbcService(apis.orders, { manual: true });
  const useGetOrderDetails = () => useEbcService(apis.orderDetail, { manual: true, avoidAccountParams: true });
  const useGetFile = () => useEbcService(apis.fileAttachment, { responseType: 'blob', manual: true, avoidAccountParams: true });
  const useGetCods = () => useEbcService(apis.cods, { manual: true });
  const useGetInvoices = () => useEbcService(apis.invoices, { manual: true });
  const useGetRecharges = () => useEbcService(apis.recharges, { manual: true });
  const usePostRecoverPassword = () => useEbcService(apis.recoverPassword, { manual: true, avoidAccountParams: true });
  const usePostChangePasswordByRequest = () => useEbcService(apis.changePasswordByRequest, { manual: true, avoidAccountParams: true });
  const usePostChangeOwnPassword = () => useEbcService(apis.changeOwnPassword, { manual: true });
  const useGetFaq = () => useEbcService(apis.faq, { avoidAccountParams: true });
  const useUploadOrderCSV = () => useEbcService(apis.uploadOrdersCSV, { manual: true, avoidAccountParams: true });
  const useGetServicesList = () => useEbcService({
    ...apis.servicesList,
    url: `${apis.servicesList.url}${selectedAccount?.pricelistId}`,
  }, { avoidAccountParams: true });
  const usePostOrder = () => useEbcService(apis.createOrder, { manual: true, avoidAccountParams: true });
  const usePutOrder = (orderId) => useEbcService({
    ...apis.updateOrder, url: `${apis.updateOrder.url}${orderId}`, manual: true, avoidAccountParams: true,
  });
  const useGetTimeSlots = () => {
    const [response, getTimeSlots] = useEbcService(apis.timeSlots, {
      manual: true,
      avoidAccountParams: true,
    });
    return [response, (zip) => {
      getTimeSlots({
        params: {
          where: [
            { value: zip },
            { value: zip },
          ],
        },
      });
    }];
  };
  const useGetDirectionPolyline = () => {
    const [response, getDirectionPolyline] = useAxios({
      method: 'get',
      url: 'https://maps.googleapis.com/maps/api/directions/json',
    }, { manual: true });

    return [response, ({ key, sender, receiver }) => getDirectionPolyline({
      params: {
        origin: sender,
        destination: receiver,
        mode: 'driving',
        key,
      },
    })];
  };
  const useGetStaticMap = () => {
    const [response, getStaticMap] = useAxios({
      url: 'https://maps.googleapis.com/maps/api/staticmap',
      responseType: 'arraybuffer',
      method: 'get',
    }, { manual: true });

    return [response, ({
      key,
      size,
      markers,
      path,
    }) => {
      const remappedMarkers = markers.map(({ color, label, address }) => `label:${label}|color:0x${color.split('#')[1]}|${address}`);

      return getStaticMap({
        params: {
          markers: remappedMarkers,
          path: `enc:${path}`,
          size,
          key,
          maptype: 'roadmap',
          format: 'png',
          visual_refresh: true,
        },
      });
    }];
  };

  const servicesState = {
    useEbcAxios,
    accountParams,
    authorizationToken,
    useGetAccounts,
    usePutAccount,
    useGetLogin,
    useGetUser,
    usePostRecoverPassword,
    usePostChangePasswordByRequest,
    usePostChangeOwnPassword,
    useGetFaq,
    useUploadOrderCSV,
    useGetServicesList,
    usePostOrder,
    usePutOrder,
    useGetCo2,
    useGetOrdersReport,
    useGetLastOrders,
    useGetOrders,
    useGetCods,
    useGetInvoices,
    useGetRecharges,
    useGetOrderDetails,
    useGetTimeSlots,
    useGetDirectionPolyline,
    useGetFile,
    useGetStaticMap,
    setAuthorizationToken,
    setAccountParams,
    setSelectedAccount,
  };

  return (
    <ServicesContext.Provider value={servicesState}>
      {children}
    </ServicesContext.Provider>
  );
};

const useServicesContext = () => useContext(ServicesContext);

ServicesProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  ServicesContext,
  ServicesProvider,
  useServicesContext,
};
