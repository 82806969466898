import { useEffect } from 'react';
import FadeIn from 'react-fade-in';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MuiToolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Co2Header from './Co2Header';
import UserAvatar from './UserAvatar';
import DrawerFooter from './DrawerFooter';
import NavigationListItem from '../components/NavigationListItem';
import EbcLogo from '../components/EbcLogo';
import useModalState from '../hooks/useModalState';
import { useUserPreferencesContext } from '../contexts/UserPreferences';
import { childrenPropType } from '../utils';
import { navigationRoutes } from '../routes';
import BackgroundDrawer from '../assets/drawer-bg.png';
import BackgroundMain from '../assets/main-bg.png';
import { useAccountContext } from '../contexts/Account';

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme: { transitions, spacing, palette: { common } }, open }) => ({
    '& .MuiDrawer-paper': {
      color: common.white,
      backgroundColor: 'transparent',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: transitions.create('width', {
          easing: transitions.easing.sharp,
          duration: transitions.duration.leavingScreen,
        }),
        width: spacing(8.5),
      }),
    },
  }),
);

const Layout = ({ children }) => {
  const {
    userPreferences: { navigationDrawerIsOpened },
    updatePreferences,
  } = useUserPreferencesContext();
  const { accounts, selectedAccount: selectedAccountId } = useAccountContext();
  const { isOpened, toggle } = useModalState(navigationDrawerIsOpened);

  const selectedAccount = accounts.find(({ id }) => id === selectedAccountId);

  // Update drawer local storage state
  useEffect(() => { updatePreferences({ navigationDrawerIsOpened: isOpened }); }, [isOpened]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="absolute"
        open={isOpened}
        sx={{ backgroundColor: ({ palette: { black } }) => black.dark }}
      >
        <MuiToolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggle}
              style={{
                marginRight: '36px',
                transition: 'margin-left 300ms ease, opacity 250ms ease',
                ...(isOpened && {
                  marginLeft: -82,
                  opacity: 0,
                }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Fade in={!isOpened}>
              <EbcLogo />
            </Fade>
          </Box>

          <Co2Header />

          <UserAvatar />
        </MuiToolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={isOpened}
        style={{
          backgroundColor: '#003A27',
          backgroundImage: `url(${BackgroundDrawer})`,
          backgroundSize: 'cover',
        }}
      >
        <MuiToolbar
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            justifyContent: 'space-between',
          }}
        >
          <EbcLogo />
          <IconButton onClick={toggle}>
            <ChevronLeftIcon />
          </IconButton>
        </MuiToolbar>

        <Divider />

        <List sx={{ flexGrow: 1 }}>
          <FadeIn transitionDuration={200}>
            {navigationRoutes
              .filter(({ accountFilterCallback }) => {
                if (!accountFilterCallback) {
                  return true;
                }
                if (!selectedAccount) {
                  return false;
                }
                return accountFilterCallback(selectedAccount);
              })
              .map(({
                title,
                icon,
                component,
                path,
                disabled,
                link,
                onClick,
              }) => (
                <NavigationListItem
                  key={title}
                  disabled={(!link && !component) || disabled}
                  path={path}
                  title={title}
                  icon={icon}
                  onClick={onClick}
                />
              ))}
          </FadeIn>
        </List>

        <DrawerFooter />
      </Drawer>

      <Box
        component="main"
        style={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <MuiToolbar />

        <Container
          maxWidth="xl"
          style={{
            position: 'relative',
            minHeight: '81.5vh',
          }}
        >
          <Box mt={8} pb={32}>
            {children}
          </Box>
        </Container>
        <img
          src={BackgroundMain}
          alt="background"
          style={{
            width: '100%',
            height: 'auto',
            marginTop: '-50vw',
            marginBottom: '-5px',
            zIndex: '-1',
          }}
        />
      </Box>
    </Box>
  );
};

Layout.propTypes = { children: childrenPropType.isRequired };

export default Layout;
