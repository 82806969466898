import { createContext, useContext, useReducer } from 'react';
import { childrenPropType, ebcLocalStorage } from '../utils';

const GlobalStateContext = createContext([]);

const globalStateReducer = (state, { type, payload }) => {
  if (type === 'update') return ({ ...state, ...payload });
  return state;
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, ebcLocalStorage.get('globalState') || {});

  const updateState = (payload) => { dispatch({ type: 'update', payload }); };

  const providerValue = {
    state,
    updateState,
  };

  return (
    <GlobalStateContext.Provider value={providerValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalStateContext = () => useContext(GlobalStateContext);

GlobalStateProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  GlobalStateContext,
  GlobalStateProvider,
  useGlobalStateContext,
};
