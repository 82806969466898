import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const MainHashtag = ({ small, sx }) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="h6"
      style={{ fontSize: small && '1rem' }}
      sx={sx}
    >
      <span dangerouslySetInnerHTML={{ __html: t('common:main-hashtag') }} />
    </Typography>
  );
};

MainHashtag.propTypes = {
  small: PropTypes.bool,
  sx: PropTypes.shape(),
};

MainHashtag.defaultProps = {
  small: false,
  sx: {},
};

export default MainHashtag;
