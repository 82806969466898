import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Avatar from '../components/Avatar';
import MenuItem from './MenuItem';
import useAvatarMenu from './useAvatarMenu';
import Skeleton from '../components/Skeleton';
import useNavigation from '../hooks/useNavigation';
import { useAccountContext } from '../contexts/Account';
import currency from '../utils/currency';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  subtext: {
    '& span.role': {
      fontWeight: 900,
      color: primary.main,
      marginLeft: spacing(0.8),
      fontSize: '1.4rem',
    },
  },
}));

const UserAvatar = () => {
  const { t } = useTranslation('pages');
  const navigateTo = useNavigation();
  const { subtext } = useStyles();
  const {
    anchorEl,
    open,
    handleClick,
    handleClose,
  } = useAvatarMenu();
  const {
    accounts,
    loading,
    selectedAccount: selectedAccountId,
    setSelectedAccount,
  } = useAccountContext();

  const handleAccountClick = (id) => () => {
    setSelectedAccount(id);
    handleClose();
  };
  const navigateToProfile = () => () => {
    handleClose();
    navigateTo('profile', true);
  };

  const {
    name: selectedAccountName,
    contractType: selectedAccountContractType,
    pricelistName: selectedAccountPriceListName,
    credit: selectedAccountCredit,
    creditCurrency: selectedAccountCreditCurrency,
  } = accounts.find(({ id }) => id === selectedAccountId) || {};

  const renderAccountTypeString = () => {
    switch (selectedAccountContractType) {
      case 'contract':
        return t('common:contract-type-contract-subtext', {
          pricelistName: selectedAccountPriceListName,
        });
      case 'rechargeable':
        return t('common:contract-type-rechargeable-subtext', {
          credit: currency(selectedAccountCredit, selectedAccountCreditCurrency),
        });
      default:
        return '';
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box textAlign="right">
          <Typography>{selectedAccountName ? t('common:greeting', { name: selectedAccountName }) : ''}</Typography>
          <Typography className={subtext}>
            <span dangerouslySetInnerHTML={{ __html: renderAccountTypeString() }} />
          </Typography>
        </Box>
        <Box ml={4}>
          <IconButton onClick={handleClick}>
            {selectedAccountName && (
            <Avatar
              alt="User"
              name={selectedAccountName}
            />
            )}
          </IconButton>
        </Box>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {loading ? (
          [...new Array(3)].map(() => (
            <MenuItem
              key={uniqueId}
              primary={<Skeleton width={[100, 200]} />}
              secondary={<Skeleton />}
            />
          ))
        ) : (
          accounts.map(({
            id,
            name,
            contractType,
            pricelistName,
          }) => (
            <MenuItem
              key={id}
              primary={name}
              secondary={contractType === 'contract' ? pricelistName : t('common:account-type-rechargeable')}
              onClick={handleAccountClick(id)}
              active={selectedAccountId === id}
            />
          )))}
        <Divider />
        <MenuItem primary={t('profile')} onClick={navigateToProfile()} />
        <MenuItem primary={t('logout')} onClick={navigateTo('logout')} />
      </Menu>
    </>
  );
};

export default UserAvatar;
