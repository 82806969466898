import { CurrencySymbolMapping } from './constants';

const currency = (num, currencyKey = 'EUR') => {
  if (!num) {
    return '-';
  }

  const roundedNum = (Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2);
  const currencySymbol = CurrencySymbolMapping[currencyKey];
  return `${roundedNum} ${currencySymbol}`;
};

export default currency;
