import { useState } from 'react';

const useModalState = (defaultState = false) => {
  const [isOpened, setIsOpened] = useState(defaultState);

  const open = () => { setIsOpened(true); };
  const close = () => { setIsOpened(false); };
  const toggle = () => { setIsOpened((prevState) => !prevState); };

  return {
    isOpened,
    open,
    close,
    toggle,
  };
};

export default useModalState;
