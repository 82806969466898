import { forwardRef } from 'react';
import ebcLogo from '../../assets/ebc-logo.png';

const EbcLogo = forwardRef((props, ref) => (
  <img
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    src={ebcLogo}
    alt="Ebc logo"
    width={180}
    ref={ref}
  />
));

export default EbcLogo;
