import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import MuiListItemText from '@mui/material/ListItemText';

const ListItemText = ({ path, title }) => {
  const { isExact = false } = useRouteMatch(path) || {};

  return (
    <MuiListItemText
      primaryTypographyProps={{
        sx: {
          fontWeight: isExact ? 900 : 400,
        },
      }}
      primary={title}
    />
  );
};

ListItemText.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ListItemText;
