import alerts from './alerts.json';
import authentication from './authentication.json';
import dashboard from './dashboard.json';
import common from './common.json';
import pages from './pages.json';
import shipping from './shipping.json';
import shippingList from './shippingList.json';
import shippingPayList from './shippingPayList.json';
import cod from './cod.json';
import shippingDetails from './shippingDetails.json';
import charts from './charts.json';
import invoice from './invoice.json';
import invoiceList from './invoiceList.json';
import profile from './profile.json';
import recharge from './recharge.json';
import rechargeList from './rechargeList.json';
import validation from './validation.json';

const it = {
  alerts,
  authentication,
  dashboard,
  common,
  pages,
  shipping,
  shippingList,
  shippingPayList,
  cod,
  shippingDetails,
  charts,
  invoice,
  invoiceList,
  profile,
  recharge,
  rechargeList,
  validation,
};

export default it;
