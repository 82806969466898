import { createContext, useContext } from 'react';
import { childrenPropType, ebcLocalStorage } from '../utils';
import useUserPreferences from '../hooks/useUserPreferences';

const UserPreferencesContext = createContext([]);

const UserPreferencesProvider = ({ children }) => {
  const userPreferencesLocalStorage = ebcLocalStorage.get('userPreferences') || undefined;
  const userPreferencesState = useUserPreferences(userPreferencesLocalStorage);

  return (
    <UserPreferencesContext.Provider value={userPreferencesState}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

const useUserPreferencesContext = () => useContext(UserPreferencesContext);

UserPreferencesProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  UserPreferencesContext,
  UserPreferencesProvider,
  useUserPreferencesContext,
};
