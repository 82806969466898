import { useReducer, createContext, useContext } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import useModalState from '../hooks/useModalState';
import { childrenPropType } from '../utils';

const EbcModalContext = createContext([]);

const initialState = {
  child: null,
  title: '',
};

const defaultOptions = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: '1200px',
  maxHeight: '95vh',
  bgcolor: 'background.paper',
  overflowY: 'scroll',
  boxShadow: 24,
  p: 4,
};

const ebcModalReducer = (_, payload) => ({ ...initialState, ...payload });
const optionsReducer = (_, payload) => ({ ...defaultOptions, ...payload });

const EbcModalProvider = ({ children }) => {
  const { isOpened, open: openModal, close } = useModalState();
  const [optionsState, optionsDispatch] = useReducer(optionsReducer, defaultOptions);
  const [modalState, modalDispatch] = useReducer(ebcModalReducer, initialState);

  const open = (child, title, options = {}) => {
    optionsDispatch(options);
    modalDispatch({ child, title });
    openModal();
  };

  return (
    <EbcModalContext.Provider value={{ isOpened, open, close }}>
      <Modal
        open={isOpened}
        onClose={close}
      >
        <Box sx={optionsState}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h1">
              {modalState.title}
            </Typography>
            <Box onClick={close}>
              <CloseIcon style={{ width: '40px', cursor: 'pointer' }} />
            </Box>
          </Box>
          {modalState.child}
        </Box>
      </Modal>
      { children }
    </EbcModalContext.Provider>
  );
};
const useEbcModalContext = () => useContext(EbcModalContext);

EbcModalProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  EbcModalContext,
  EbcModalProvider,
  useEbcModalContext,
};
