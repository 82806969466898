import RAvatar from 'react-avatar';
import PropTypes from 'prop-types';

const Avatar = ({ name }) => (
  <RAvatar
    name={name}
    round
  />
);

Avatar.propTypes = {
  name: PropTypes.string,
};

Avatar.defaultProps = {
  name: undefined,
};

export default Avatar;
