import PropTypes from 'prop-types';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiListItemText from '@mui/material/ListItemText';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { childrenPropType } from '../utils';

const MenuItem = ({
  primary,
  secondary,
  onClick,
  active,
}) => (
  <MuiMenuItem
    onClick={onClick}
    sx={{ py: 2 }}
    selected={active}
  >
    {active && (
    <MuiListItemIcon>
      <CheckIcon />
    </MuiListItemIcon>
    )}
    <MuiListItemText
      inset={!active}
      primary={primary}
      secondary={secondary}
    />
  </MuiMenuItem>
);

MenuItem.propTypes = {
  primary: childrenPropType.isRequired,
  secondary: childrenPropType,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

MenuItem.defaultProps = {
  secondary: undefined,
  onClick: () => {},
  active: false,
};

export default MenuItem;
