import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { encode } from 'js-base64';
import { useAlertContext } from './Alert';
import { useServicesContext } from './Services';
import { useUserDataContext } from './UserData';
import { childrenPropType, ebcLocalStorage } from '../utils';
import useNavigation from '../hooks/useNavigation';

const { REACT_APP_PORTAL_URL } = process.env;

const AuthenticationContext = createContext([]);

const AuthenticationProvider = ({ children }) => {
  const { t } = useTranslation(['alerts', 'authentication']);
  const {
    useGetLogin,
    setAuthorizationToken,
    usePostRecoverPassword,
    usePostChangePasswordByRequest,
    usePostChangeOwnPassword,
  } = useServicesContext();
  const { setUserData } = useUserDataContext();
  const [{ loading: loadingLogin }, ebcLogin] = useGetLogin();
  const [{ loading: loadingRecover }, ebcRecover] = usePostRecoverPassword();
  const [{
    loading: loadingChangePasswordByRequest,
  }, ebcChangePasswordByRequest] = usePostChangePasswordByRequest();
  const [{ loading: loadingChangeOwnPassword }, ebcChangeOwnPassword] = usePostChangeOwnPassword();

  const navigateTo = useNavigation();
  const { open } = useAlertContext();

  /**
   * Check errors
   */
  const checkErrors = (error, {
    severity = 'error',
    position,
    content,
    redirectOnAuthFail = true,
  } = {}) => {
    const { response } = error;
    const duration = 5000;
    if (!response) {
      open({ content: t('generic.network-error'), duration });
    } else {
      const { status } = response;

      const getErrorPosition = () => {
        if (position) return position;
        if (status === 401 || status === 400 || status === 403) return ({ vertical: 'top', horizontal: 'center' });
        return position;
      };

      const getErrorMessage = () => {
        if (content) return content;
        if (status === 401 || status === 403) return t('login.wrong-credentials');
        if (status === 400) return t('authorization.generic-error');
        return content;
      };

      if (status === 401 || status === 403 || status === 400) {
        open({
          duration,
          severity,
          position: getErrorPosition(),
          content: getErrorMessage(),
        });
        if (redirectOnAuthFail === true) {
          navigateTo('login', true);
        }
      }
    }
  };

  /**
   * Check authentication
   */
  const checkAuth = () => {
    if (!ebcLocalStorage.get('userData')) {
      checkErrors({ response: { status: 400 } });
    }
  };

  /**
   * Login method
   */
  const login = async ({ username, password }) => {
    const loginAuthorizationEncoded = encode(`${username}:${password}`);
    try {
      const { data: { token, user }, status } = await ebcLogin({ headers: { 'Espo-Authorization': loginAuthorizationEncoded } });
      if (status === 200) {
        const authorizationEncoded = encode(`${username}:${token}`);
        setAuthorizationToken(authorizationEncoded);
        setUserData(user);
        navigateTo('dashboard', true);
      }
    } catch (e) {
      checkErrors(e);
    }
  };

  const recoverPassword = async ({ email }) => {
    try {
      const body = {
        userName: email,
        emailAddress: email,
        url: REACT_APP_PORTAL_URL,
      };
      await ebcRecover({ data: body });
      open({
        content: t('authentication:change-password-mail-sent'),
        position: { vertical: 'top', horizontal: 'center' },
      });
    } catch (e) {
      open({
        severity: 'error',
        content: t('authentication:change-password-mail-error'),
        position: { vertical: 'top', horizontal: 'center' },
      });
      throw e;
    }
  };

  const changePasswordByRequest = async ({ password, requestId }) => {
    try {
      const body = {
        requestId,
        password,
      };
      await ebcChangePasswordByRequest({ data: body });
      navigateTo('/login', true);
    } catch (e) {
      open({
        severity: 'error',
        content: t('authentication:change-password-request-error'),
        position: { vertical: 'top', horizontal: 'center' },
      });
      throw e;
    }
  };

  const changeOwnPassword = async ({ currentPassword, password }) => {
    const body = {
      currentPassword,
      password,
    };

    try {
      await ebcChangeOwnPassword({ data: body });
      open({
        severity: 'success',
        content: t('authentication:password-change-success'),
      });
    } catch (e) {
      const { response: { status } } = e;
      checkErrors(e, {
        redirectOnAuthFail: false,
        position: { vertical: 'top', horizontal: 'center' },
        content: status === 403 ? t('authentication:change-password-authentication-error') : null,
      });
      throw e;
    }
  };

  /**
   * Logout method
   */
  const logout = () => {
    ebcLocalStorage.clearStorage();
    navigateTo('login', true);
  };

  /**
   * Enhanced Provider state
   */
  const providerState = {
    login: [{ loading: loadingLogin }, login],
    recoverPassword: [{ loading: loadingRecover }, recoverPassword],
    changePasswordByRequest: [{ loading: loadingChangePasswordByRequest }, changePasswordByRequest],
    changeOwnPassword: [{ loading: loadingChangeOwnPassword }, changeOwnPassword],
    logout,
    checkErrors,
    checkAuth,
  };

  return (
    <AuthenticationContext.Provider value={providerState}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthenticationContext = () => useContext(AuthenticationContext);

AuthenticationProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  AuthenticationContext,
  AuthenticationProvider,
  useAuthenticationContext,
};
